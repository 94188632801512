
import { defineComponent } from 'vue';

import useBreakpoints from '@/use/useBreakpoints';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { ResponsiveImageSet } from '@/types';
import TierLevelCarousel from '../../components/TierLevelCarousel.vue';
import CommunityCarousel from '../../components/Sliders/CommunityCarousel.vue';
import CommunityClass from '../../components/CommunityClass.vue';
import Button from '@/components/Button/Button.vue';

import mHero1 from '../../assets/hero_mobile.png';
import mHero2 from '../../assets/hero_mobile@2x.png';
import mHero3 from '../../assets/hero_mobile@3x.png';
import dHero1 from '../../assets/hero_desktop.png';
import dHero2 from '../../assets/hero_desktop@2x.png';
import dHero3 from '../../assets/hero_desktop@3x.png';

import mImage_left1 from '../../assets/image_left_mobile.png';
import mImage_left2 from '../../assets/image_left_mobile@2x.png';
import mImage_left3 from '../../assets/image_left_mobile@3x.png';
import dImage_left1 from '../../assets/image_left_desktop.png';
import dImage_left2 from '../../assets/image_left_desktop@2x.png';
import dImage_left3 from '../../assets/image_left_desktop@3x.png';

import mFlow1 from '../../assets/flow_mobile.png';
import mFlow2 from '../../assets/flow_mobile@2x.png';
import mFlow3 from '../../assets/flow_mobile@3x.png';
import dFlow1 from '../../assets/flow_desktop.png';
import dFlow2 from '../../assets/flow_desktop@2x.png';
import dFlow3 from '../../assets/flow_desktop@3x.png';

import mTier_level1 from '../../assets/tier_level_mobile.png';
import mTier_level2 from '../../assets/tier_level_mobile@2x.png';
import mTier_level3 from '../../assets/tier_level_mobile@3x.png';
import dTier_level1 from '../../assets/tier_level_desktop.png';
import dTier_level2 from '../../assets/tier_level_desktop@2x.png';
import dTier_level3 from '../../assets/tier_level_desktop@3x.png';

import mTier_level_silver1 from '../../assets/tier_level_silver_mobile.png';
import mTier_level_silver2 from '../../assets/tier_level_silver_mobile@2x.png';
import mTier_level_silver3 from '../../assets/tier_level_silver_mobile@3x.png';
import dTier_level_silver1 from '../../assets/tier_level_silver_desktop.png';
import dTier_level_silver2 from '../../assets/tier_level_silver_desktop@2x.png';
import dTier_level_silver3 from '../../assets/tier_level_silver_desktop@3x.png';

import mTier_level_gold1 from '../../assets/tier_level_gold_desktop.png';
import mTier_level_gold2 from '../../assets/tier_level_gold_desktop@2x.png';
import mTier_level_gold3 from '../../assets/tier_level_gold_desktop@3x.png';
import dTier_level_gold1 from '../../assets/tier_level_gold_desktop.png';
import dTier_level_gold2 from '../../assets/tier_level_gold_desktop@2x.png';
import dTier_level_gold3 from '../../assets/tier_level_gold_desktop@3x.png';

import mTier_level_platinum1 from '../../assets/tier_level_platinum_desktop.png';
import mTier_level_platinum2 from '../../assets/tier_level_platinum_desktop@2x.png';
import mTier_level_platinum3 from '../../assets/tier_level_platinum_desktop@3x.png';
import dTier_level_platinum1 from '../../assets/tier_level_platinum_desktop.png';
import dTier_level_platinum2 from '../../assets/tier_level_platinum_desktop@2x.png';
import dTier_level_platinum3 from '../../assets/tier_level_platinum_desktop@3x.png';

import img_podcasts_de1 from '../../assets/img_podcasts_de.png';
import img_podcasts_de2 from '../../assets/img_podcasts_de@2x.png';
import img_podcasts_de3 from '../../assets/img_podcasts_de@3x.png';

import img_story_de1 from '../../assets/img_story_de.png';
import img_story_de2 from '../../assets/img_story_de@2x.png';
import img_story_de3 from '../../assets/img_story_de@3x.png';

import img_community_wall_de1 from '../../assets/img_community_wall_de.png';
import img_community_wall_de2 from '../../assets/img_community_wall_de@2x.png';
import img_community_wall_de3 from '../../assets/img_community_wall_de@3x.png';

export default defineComponent({
  components: {
    ResponsiveImage,
    Button,
    TierLevelCarousel,
    CommunityCarousel,
    CommunityClass,
  },
  props: {
    hasRedeemedPoints: { type: Boolean, default: false },
    onRedeemPoints: { type: Function, defaul: () => null },
  },
  setup() {
    const heroImage: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: mHero1,
        x2: mHero2,
        x3: mHero3,
      },
      desktop: {
        x1: dHero1,
        x2: dHero2,
        x3: dHero3,
      },
    };

    const flowImage: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: mFlow1,
        x2: mFlow2,
        x3: mFlow3,
      },
      desktop: {
        x1: dFlow1,
        x2: dFlow2,
        x3: dFlow3,
      },
    };

    const leftImage: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: mImage_left1,
        x2: mImage_left2,
        x3: mImage_left3,
      },
      desktop: {
        x1: dImage_left1,
        x2: dImage_left2,
        x3: dImage_left3,
      },
    };

    const tierLevel: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: mTier_level1,
        x2: mTier_level2,
        x3: mTier_level3,
      },
      desktop: {
        x1: dTier_level1,
        x2: dTier_level2,
        x3: dTier_level3,
      },
    };

    const tierLevelSilver: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: mTier_level_silver1,
        x2: mTier_level_silver2,
        x3: mTier_level_silver3,
      },
      desktop: {
        x1: dTier_level_silver1,
        x2: dTier_level_silver2,
        x3: dTier_level_silver3,
      },
    };

    const tierLevelGold: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: mTier_level_gold1,
        x2: mTier_level_gold2,
        x3: mTier_level_gold3,
      },
      desktop: {
        x1: dTier_level_gold1,
        x2: dTier_level_gold2,
        x3: dTier_level_gold3,
      },
    };

    const tierLevelPlatinum: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: mTier_level_platinum1,
        x2: mTier_level_platinum2,
        x3: mTier_level_platinum3,
      },
      desktop: {
        x1: dTier_level_platinum1,
        x2: dTier_level_platinum2,
        x3: dTier_level_platinum3,
      },
    };

    const imgPodcasts: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: img_podcasts_de1,
        x2: img_podcasts_de2,
        x3: img_podcasts_de3,
      },
      desktop: {
        x1: img_podcasts_de1,
        x2: img_podcasts_de2,
        x3: img_podcasts_de3,
      },
    };

    const imgStory: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: img_story_de1,
        x2: img_story_de2,
        x3: img_story_de3,
      },
      desktop: {
        x1: img_story_de1,
        x2: img_story_de2,
        x3: img_story_de3,
      },
    };

    const imgCommunityWall: ResponsiveImageSet = {
      alt: 'Spring Raffle',
      mobile: {
        x1: img_community_wall_de1,
        x2: img_community_wall_de2,
        x3: img_community_wall_de3,
      },
      desktop: {
        x1: img_community_wall_de1,
        x2: img_community_wall_de2,
        x3: img_community_wall_de3,
      },
    };

    const breakpointsService = useBreakpoints();

    const communitySliderItems = [
      {
        images: imgCommunityWall,
        header: 'Teile einen Beitrag auf der Community Wall.',
        url: 'https://de.iqosclub.com/iqos-community',
      },
      {
        images: imgStory,
        header: 'Eine IQOS Club Story lesen.',
        url: 'https://de.iqosclub.com/stories',
      },
      {
        images: imgPodcasts,
        header: 'Eine Folge vom IQOS Podcast anhören.',
        url: 'https://de.iqosclub.com/podcasts',
      },
    ];

    return {
      heroImage,
      flowImage,
      leftImage,
      tierLevel,
      tierLevelSilver,
      tierLevelGold,
      tierLevelPlatinum,
      imgStory,
      imgPodcasts,
      imgCommunityWall,
      communitySliderItems,
      isMobile: !breakpointsService.isMd(),
      isExtraScreen: breakpointsService.isXl(),
    };
  },
});
