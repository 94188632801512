
import { defineComponent, PropType } from 'vue';
import 'vueperslides/dist/vueperslides.css';

import { ResponsiveImageSet } from '@/types';

import Link from '@/components/Link/Link.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

const CHECK_DRAG_SLIDER = 'checkSliderDrag';

export default defineComponent({
  components: {
    ResponsiveImage,
    Link,
  },
  props: {
    classItem: {
      type: Object as PropType<{
        images: ResponsiveImageSet | null;
        url: string | null;
        header: string | null;
      }>,
      required: true,
    },
  },
  emits: [CHECK_DRAG_SLIDER],
  setup(props, { emit }) {
    const onCheckSlideDrag = (event) => {
      emit(CHECK_DRAG_SLIDER, event);
    };

    return { onCheckSlideDrag };
  },
});
