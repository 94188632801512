import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tierr-level-carousel md:relative" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "absolute left-0 bottom-0 z-10 p-2 text-fs-22-lh-27-ls-0 text-white font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_VueperSlide = _resolveComponent("VueperSlide")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Carousel, {
      class: "px-0",
      bullets: false,
      arrows: !_ctx.isMobile,
      breakpoints: _ctx.carouselBreakpointsConfig
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tierLevelImages, (tierLevelImage, index) => {
          return (_openBlock(), _createBlock(_component_VueperSlide, { key: index }, {
            content: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.tierLevelLinks[index]
              }, [
                _createVNode(_component_ResponsiveImage, {
                  class: "ml-8",
                  responsiveImageSet: tierLevelImage
                }, null, 8, ["responsiveImageSet"]),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tierLevelTexts[index]), 1)
              ], 8, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["arrows", "breakpoints"])
  ]))
}