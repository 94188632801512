
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

import SpringRaffleHome from './SpringRaffleHome.vue';

export default defineComponent({
  components: {
    SpringRaffleHome,
  },
  setup() {
    const store = useStore();
    // store.dispatch('springRaffleModule/checkPointsRedeemed');
    const hasRedeemedPoints = computed(
      () => store.state.springRaffleModule.hasRedeemedPoints
    );
    const onRedeemPoints = () => {
      store.dispatch('springRaffleModule/triggerRedeemPoints');
    };
    return {
      hasRedeemedPoints,
      onRedeemPoints,
    };
  },
});
