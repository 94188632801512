
import { defineComponent, PropType, computed, ref } from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

import { ResponsiveImageSet } from '@/types';
import useBreakpoints from '@/use/useBreakpoints';

import CommunityClass from '../CommunityClass.vue';

export default defineComponent({
  components: {
    CommunityClass,
    VueperSlides,
    VueperSlide,
  },
  props: {
    sliderItems: {
      type: Array as PropType<
        {
          images: ResponsiveImageSet | null;
          url: string | null;
          header: string | null;
        }[]
      >,
      required: true,
    },
  },
  setup() {
    const communityCarouselRef = ref(null);

    const breakpoints = useBreakpoints();

    const sliderHeight = computed(() =>
      breakpoints.isMd() ? '440px' : '194px'
    );

    const preventNavigationOnSlideDrag = (event) => {
      if (communityCarouselRef.value.justDragged()) {
        event.preventDefault();
      }
    };

    return {
      sliderHeight,
      preventNavigationOnSlideDrag,
      communityCarouselRef,
    };
  },
});
