
import { VueperSlide } from 'vueperslides';
import { defineComponent, computed, ComputedRef, PropType } from 'vue';

import useCarousel from '@/use/useCarousel';
import useBreakpoints from '@/use/useBreakpoints';

import { ResponsiveImageSet } from '@/types';

import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import Carousel from '@/components/Carousel/Carousel.vue';

import 'vueperslides/dist/vueperslides.css';

const visibleSlidesPerBreakpoint = {
  xl: 1,
  lg: 1,
  md: 1,
  sm: 1,
};

export default defineComponent({
  components: {
    VueperSlide,
    Carousel,
    ResponsiveImage,
  },
  props: {
    //TODO: temprory varibales are added, change prop object
    tierLevelImages: {
      type: Array as PropType<ResponsiveImageSet[]>,
      default: () => [],
    },
    tierLevelTexts: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    tierLevelLinks: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    const breakpointsService = useBreakpoints();
    const { breakpoints } = breakpointsService;
    const isDesktop = breakpointsService.isLg();
    const isMobile = !breakpointsService.isMd();

    const totalItems: ComputedRef<number> = computed(
      () => props.tierLevelImages?.length
    );

    const carouselBreakpoints = {
      9999: {
        // larger than lg
        visibleSlides: visibleSlidesPerBreakpoint.xl,
        touchable: false,
        initSlide: 1,
      },
      [breakpoints.xl - 1]: {
        // lg
        visibleSlides: visibleSlidesPerBreakpoint.lg,
        touchable: false,
        initSlide: 1,
      },
      [breakpoints.lg - 1]: {
        // md
        visibleSlides: visibleSlidesPerBreakpoint.md,
        touchable: false,
        initSlide: 1,
      },
      [breakpoints.md - 1]: {
        // sm and lower
        visibleSlides: visibleSlidesPerBreakpoint.sm,
        touchable: isMobile,
        initSlide: 1,
      },
    };

    const { carouselBreakpointsConfig } = useCarousel({
      visibleSlidesPerBreakpoint,
      totalItems,
      carouselBreakpoints,
    });

    return {
      carouselBreakpointsConfig,
      isDesktop,
      isMobile,
      totalItems,
    };
  },
});
